<script setup>
import router from "../../../router";
import { ref, inject, onMounted, watch } from "vue";
import SidebarApp from "../../../views/inbox/components/SidebarApp.vue";
import KPIs from "../common/analytics/KPIs.vue";
import { toast } from "vue3-toastify";
import { sendGrid } from "../providers/api/sendgrid";

const {
  inboxBusinessClients,
  handlePageSelected,
  updateInbox,
  inbox,
  fetchMetaInfo,
  metaInfo,
  getEmailMetrics,
  getMailboxMetadata,
} = inject("inbox");

const tags = ref([]);
const selectedCategory = ref(null);
const startDate = ref("");
const endDate = ref("");

onMounted(async () => {
  if (!inbox.value.id) {
    router.push(`/inbox/`);
  }

  if (inbox.value.id) {
    const data = await getMailboxMetadata(inbox.value.id);

    const mailingTags = data.mailing_tags ? data.mailing_tags : null;

    if (mailingTags) {
      tags.value.push(...mailingTags);
    }
  }
});

const chartData = ref({
  labels: [],
  datasets: [
    {
      label: "Aberturas",
      backgroundColor: "#ff4d4d",
      data: [],
    },
    {
      label: "Aberturas Únicas",
      backgroundColor: "#ff9999",
      data: [],
    },
  ],
});

const chartOptions = ref({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
});

const kpis = ref({
  requests: 0,
  delivered: 0,
  opens: 0,
  unique_opens: 0,
  clicks: 0,
  unique_clicks: 0,
  unsubscribes: 0,
  bounces: 0,
  spam_reports: 0,
  blocks: 0,
  bounce_drops: 0,
  spam_report_drops: 0,
  unsubscribe_drops: 0,
  schedulings: 0,
});

const fetchData = async () => {
  if (!startDate.value || !endDate.value || !selectedCategory.value) {
    toast("Oops! Parece que algum campo não foi selecionado!", {
      position: "top-right",
      autoClose: 5000,
      type: "error",
      toastStyle: {
        "--toastify-icon-color-error": "#c60d31",
        "--toastify-color-error": "#c60d31",
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "#c60d31",
      },
    });
    return;
  }

  let id;

  try {
    id = toast.loading("Buscando métricas...", {
      position: "top-right",
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
    });

    console.log("Categoria selecionada:", selectedCategory.value);
    const data = await sendGrid(
      selectedCategory.value,
      startDate.value,
      endDate.value
    );

    toast.update(id, {
      render: "Métricas buscadas com sucesso!",
      type: "success",
      isLoading: false,
      autoClose: 5000,
      toastStyle: {
        fontFamily: "Fivo Sans Modern Regular",
      },
      progressStyle: {
        backgroundColor: "green",
      },
    });

    console.log(data);

    const labels = data.map((item) => item.date);
    const opens = data.map((item) => item.stats[0].metrics.opens);
    const uniqueOpens = data.map((item) => item.stats[0].metrics.unique_opens);

    chartData.value.labels = labels;
    chartData.value.datasets[0].data = opens;
    chartData.value.datasets[1].data = uniqueOpens;

    const newKpis = {
      requests: 0,
      delivered: 0,
      opens: 0,
      unique_opens: 0,
      clicks: 0,
      unique_clicks: 0,
      unsubscribes: 0,
      bounces: 0,
      spam_reports: 0,
      blocks: 0,
      bounce_drops: 0,
      spam_report_drops: 0,
      unsubscribe_drops: 0,
    };

    data.forEach((item) => {
      const metrics = item.stats[0].metrics;
      newKpis.requests += metrics.requests;
      newKpis.delivered += metrics.delivered;
      newKpis.opens += metrics.opens;
      newKpis.unique_opens += metrics.unique_opens;
      newKpis.clicks += metrics.clicks;
      newKpis.unique_clicks += metrics.unique_clicks;
      newKpis.unsubscribes += metrics.unsubscribes;
      newKpis.bounces += metrics.bounces;
      newKpis.spam_reports += metrics.spam_reports;
      newKpis.blocks += metrics.blocks;
      newKpis.bounce_drops += metrics.bounce_drops;
      newKpis.spam_report_drops += metrics.spam_report_drops;
      newKpis.unsubscribe_drops += metrics.unsubscribe_drops;
    });

    console.log(inbox.value);
    console.log(inbox.value.business_client_id);

    const { schedulings_count: schedulings } = await getEmailMetrics(
      inbox.value.business_client_id,
      startDate.value,
      endDate.value
    );

    newKpis.schedulings = schedulings;

    kpis.value = newKpis;

    taxaRetorno.value = (schedulings * 100) / newKpis.delivered;

    console.log("Chart Data:", chartData.value);
    console.log("KPIs:", kpis.value);
  } catch (error) {
    console.error("Error fetching data:", error);
    alert("Failed to fetch data. Please check your API token.");
  }
};

watch(
  () => inbox.value,
  async (newValue, oldValue) => {
    const data = await getMailboxMetadata(newValue.id);

    if (!data || !data.mailing_tags || data.mailing_tags.length === 0) {
      inbox.value = oldValue;
      router.push(`/inbox/${inbox.value.id}/dashboard`);
    } else {
      await fetchMetaInfo();

      tags.value.length = 0;
      tags.value.push(...data.mailing_tags);
      kpis.value = {
        requests: 0,
        delivered: 0,
        opens: 0,
        unique_opens: 0,
        clicks: 0,
        unique_clicks: 0,
        unsubscribes: 0,
        bounces: 0,
        spam_reports: 0,
        blocks: 0,
        bounce_drops: 0,
        spam_report_drops: 0,
        unsubscribe_drops: 0,
        schedulings: 0,
      };
    }
  }
);

//CALCULADORA

// Dados reativos
const taxaRetorno = ref("");
const objetivoReunioes = ref("");
const diasSelecionados = ref([]);
const modalVisivel = ref(false);
const resultadoTexto = ref("");

// Lista de dias da semana
const diasSemana = [
  "segunda-feira",
  "terça-feira",
  "quarta-feira",
  "quinta-feira",
  "sexta-feira",
  "sábado",
  "domingo",
];

// Função para calcular os disparos necessários
const calcular = () => {
  const taxa = parseFloat(taxaRetorno.value / 100);
  const objetivo = parseInt(objetivoReunioes.value);

  if (
    isNaN(taxa) ||
    isNaN(objetivo) ||
    taxa <= 0 ||
    diasSelecionados.value.length === 0
  ) {
    alert(
      "Por favor, insira valores válidos para a taxa de retorno, o objetivo de reuniões e selecione ao menos um dia da semana."
    );
    return;
  }

  const disparosNecessarios = Math.ceil(objetivo / taxa);
  const semanasNoMes = 4;
  const disparosPorDia = Math.ceil(
    disparosNecessarios / (diasSelecionados.value.length * semanasNoMes)
  );

  const leadsNecessarios = Math.ceil(disparosNecessarios / 3);
  let resultado = `Para alcançar ${objetivo} reuniões, você precisará fazer aproximadamente ${disparosNecessarios} disparos.<br><br>`;
  resultado += `A quantidade de leads aproximados é: ${leadsNecessarios}<br><br>`;
  resultado += `Distribuição semanal aproximada:<br><br>`;

  for (let semana = 1; semana <= semanasNoMes; semana++) {
    resultado += `<strong>Semana ${semana}:</strong><br>`;
    diasSelecionados.value.forEach((dia) => {
      resultado += `${dia}: ${disparosPorDia} disparos<br>`;
    });
    resultado += `<br>`;
  }

  resultadoTexto.value = resultado;
  modalVisivel.value = true;
};

// Função para fechar o modal
const fecharModal = () => {
  modalVisivel.value = false;
};
</script>
<template>
  <div class="dashboard-wrapper">
    <SidebarApp
      class="sidebar-app"
      :updateInbox="updateInbox"
      :inboxBusinessClients="inboxBusinessClients"
      @pageSelected="handlePageSelected"
    />
    <div class="dashboard-container">
      <h1 v-if="metaInfo[0] && metaInfo[0].company_name">
        Dashboard - {{ metaInfo[0].company_name.value }}
      </h1>
      <div class="select-wrapper">
        <label for="category">Selecione categoria</label>
        <select v-model="selectedCategory" name="category" id="category">
          <option v-for="(tag, index) in tags" :value="tag" :key="index">
            {{ tag }}
          </option>
        </select>
      </div>
      <div class="date-picker">
        <label for="start-date">Data inicial:</label>
        <input type="date" id="start-date" v-model="startDate" />
        <label for="end-date">Data final:</label>
        <input type="date" id="end-date" v-model="endDate" />
        <button @click="fetchData">Buscar métricas</button>
      </div>
      <KPIs :kpis="kpis" />
      <div class="chart-container">
        <div>
          <h1 class="title">Calculo de disparos</h1>

          <div class="container">
            <div class="upper-content">
              <label for="taxa-retorno">Taxa de Retorno (%)</label>
              <input
                type="text"
                id="taxa-retorno"
                v-model="taxaRetorno"
                placeholder="0,00%"
              />
              <label for="objetivo-reunioes"
                >Qual é o objetivo de reuniões a ser alcançada por mês?</label
              >
              <input
                type="text"
                id="objetivo-reunioes"
                v-model="objetivoReunioes"
                placeholder="0"
              />
            </div>

            <div class="checkbox-group">
              <label v-for="dia in diasSemana" :key="dia">
                <input
                  class="calculator-btn"
                  type="checkbox"
                  :value="dia"
                  v-model="diasSelecionados"
                />
                {{ dia }}
              </label>
            </div>

            <button class="calculate-btn" @click="calcular">Calcular</button>
          </div>

          <div v-if="modalVisivel" class="modal">
            <div class="modal-content">
              <p v-html="resultadoTexto"></p>
              <button @click="fecharModal">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.sidebar-app {
  width: 250px;
  height: 100vh;
}

.upper-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dashboard-wrapper {
  display: flex;
  min-height: 100vh;
}

.dashboard-container {
  background-color: #f7f7f7;
  padding: 30px;
  border-radius: 15px;
  flex-grow: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #e60000;
  font-size: 2rem;
  margin-bottom: 20px;
}

.select-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.date-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

label {
  font-weight: bold;
  margin: 0 15px;
  color: #333;
}

input[type="date"],
input[type="text"],
select {
  padding: 4px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  transition: border 0.3s ease;
}

input[type="date"]:focus,
input[type="text"]:focus,
select:focus {
  border-color: #ff4d4d;
  outline: none;
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #ff4d4d;
  color: white;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #e60000;
}

button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.calculate-btn {
  margin-left: 221px;
}

.chart-container {
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container {
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
}

.checkbox-group {
  margin-bottom: 25px;
}

.checkbox-group label {
  display: inline-block;
  margin: 5px 15px;
  font-size: 1rem;
  color: #333;
}

input[type="checkbox"] {
  accent-color: #ff4d4d;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 15px;
  text-align: center;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  height: 1422px;
}

.modal-content p {
  flex-direction: 1.2rem;
  margin-top: 306px;
}

.modal-content button {
  padding: 10px 20px;
  background-color: #ff4d4d;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.modal-content button:hover {
  background-color: #e60000;
}

@media (max-width: 768px) {
  .dashboard-wrapper {
    flex-direction: column;
  }

  .dashboard-container {
    width: 100%;
    padding: 15px;
  }

  .container {
    width: 100%;
    max-width: 100%;
    padding: 15px;
  }

  .select-wrapper,
  .date-picker {
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    width: 100%;
    padding: 12px 0;
  }

  .checkbox-group label {
    display: block;
    margin: 5px 0;
  }
}
</style>
